import React from 'react'
import styled from '@emotion/styled'


export default styled.div`
  flex: 1;
  font-family:"IBM Plex Mono", -apple-system,BlinkMacSystemFont, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
`